<template>
  <div class="top-record-section">
    <a-card :class="linkCardType">
      <draggable
        v-if="records && records.length"
        v-model="records"
        v-bind="dragOptions"
        @start="drag = true"
        @end="sortRecords"
      >
        <transition-group tag="div" class="grid record-section-body" type="transition" :name="!drag ? 'flip-grid' : null">
          <a-card
            v-for="(record) in records"
            :key="record.id"
            :bordered="false"
            class="link-card"
            :class="record.is_active ? '' : 'disabled'"
          >
            <div slot="cover" class="link-card-container">
              <img
                class="link-card-cover-img"
                :src="record.img_path"
                @click="editLinkModal(record)"
              />
              <span class="text-gray-6">{{ record.localizations[currentLocale].title }}</span>
            </div>
          </a-card>
        </transition-group>
      </draggable>
      <div slot="title" class="mt-1">
        <div class="d-flex font-size-20">
          <span class="section-title">
            {{ title }}
          </span>
        </div>
      </div>
      <div slot="extra" class="d-flex align-items-center section-extra font-size-20 text-right">
        <div class="mt-0 d-flex align-items-center">
          <span>
            <a-button type="primary" icon="plus" @click="createLinkModal">
              Add
            </a-button>
          </span>
          <div class="mr-auto ml-3" style="margin-top: 0.1rem">
            <a-tooltip title="Edit section" placement="bottom">
              <a-icon @click="editSection" type="edit" class="main-tabs__sections__icons text-primary" />
            </a-tooltip>
          </div>
          <a-tooltip title="Dublicate section" placement="bottom">
            <a-icon type="copy" class="font-size-18 text-primary ml-3" @click="duplicateSection" />
          </a-tooltip>
          <span class="ml-3" v-if="activeRecordsCount() < records.length">
            <a-tooltip title="Publish all" placement="bottom">
              <a-popconfirm
                title="Sure to publish all records?"
                @confirm="activateAllRecords"
              >
                <a href="javascript:" class="text-primary">
                  <a-icon :type="publishLoading ? 'loading' : 'cloud-upload'" />
                </a>
              </a-popconfirm>
            </a-tooltip>
          </span>
        </div>
        <div class="mr-auto ml-3">
          <span class="">
            <a-tooltip v-if="index < maxIndex - 1" title="Move down" placement="bottom">
              <a-icon @click="moveDown" type="down" class="movable move-down text-primary" />
            </a-tooltip>
            <a-icon v-else type="down" class="movable text-gray-5" />
          </span>
          <span class="ml-3">
            <a-tooltip v-if="index > 0" title="Move up" placement="bottom" >
              <a-icon @click="moveUp" type="up" class="movable move-up text-primary" />
            </a-tooltip>
            <a-icon v-else type="up" class="movable text-gray-5" />
          </span>
        </div>
        <a href="javascript:" class="text-primary ml-3" @click.stop="changeStatusSection">
          <a-switch
            v-model="isActiveSectionStatus"
            :disabled="disabledSwitch"
            class="ml-3"
          />
        </a>
      </div>
    </a-card>
    <a-modal
      v-model="linkModal"
      :title="linkObj && linkObj.id ? `Edit link [#${linkObj.id}]` : 'Add link'"
      :footer="null"
      class="link-modal"
      width="380px"
      :destroyOnClose="true"
      @cancel="closeLinkModal"
    >
      <link-modal
        @createLink="handleCreateLink"
        @updateLink="handleUpdateLink"
        @deleteLink="handleDeleteLink"
        @closeModalFunction="closeLinkModal"
        :link-obj="linkObj"
        :section-id="sectionId"
        :section-type="sectionType"
        :langs="this.langs"
        ref="linkModal"
      />
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import draggable from 'vuedraggable'
import TopService from '@/services/api/apiTopService'

export default {
  name: 'recordSection',
  props: ['recordsData', 'sectionId', 'sectionType', 'isActive', 'title', 'index', 'maxIndex', 'langs'],
  components: {
    draggable,
    'link-modal': () => import('../modals/linkModal.vue'),
  },
  watch: {
    isActive(value) {
      this.isActiveSectionStatus = value
    },
  },
  computed: {
    ...mapState(['user']),
    currentLocale() {
      if (this.$i18n.locale === 'en-US') {
        return 0
      } else {
        return 1
      }
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    linkCardType() {
      return (() => {
        switch (this.sectionType.name) {
          case 'links_small': return 'link-card-type-small'
          case 'links_medium': return 'link-card-type-medium'
          case 'links_large': return 'link-card-type-large'
          default: return 'link-card-type-small'
        }
      })()
    },
    disabledSwitch() {
      const activeRecord = this.records?.find(record => record.is_active)
      if (!activeRecord && this.isActiveSectionStatus) {
        this.changeStatusSection()
      }
      return !this.records?.find(record => record.is_active)
    },
  },
  data: () => ({
    accessModule: 'top',
    records: [],
    isActiveSectionStatus: false,
    drag: false,
    linkObj: undefined,
    linkModal: false,
    publishLoading: false,
  }),
  mounted () {
    this.records = this.recordsData
    this.isActiveSectionStatus = this.isActive
  },
  created() {},
  methods: {
    createLinkModal() {
      this.linkObj = null
      this.linkModal = true
    },
    editLinkModal(obj) {
      this.linkObj = JSON.parse(JSON.stringify(obj))
      this.linkModal = true
    },
    closeLinkModal() {
      this.linkModal = false
    },
    editSection() {
      if (this.sectionType.name === 'matches') {
        this.$router.push('/top-matches')
        return
      }
      this.$emit('editSection')
    },
    duplicateSection() {
      this.$emit('duplicateSection')
    },
    handleCreateLink(created) {
      this.closeLinkModal()
      this.records.unshift(created)
      this.sortRecords()
    },
    handleUpdateLink(updatedRecord) {
      const indexRecord = this.records.findIndex((record) => record.id === updatedRecord.id)
      this.records.splice(indexRecord, 1, updatedRecord)
      this.closeLinkModal()
    },
    handleDeleteLink(deletedId) {
      this.records.splice(this.records.findIndex(x => x.id === deletedId), 1)
      this.closeLinkModal()
    },
    moveDown() {
      this.$emit('moveDownSection')
    },
    moveUp() {
      this.$emit('moveUpSection')
    },
    changeStatusSection() {
      this.$emit('changeStatusSection')
    },
    sortRecords() {
      const recordSort = this.records.map((record, index, recordArr) => {
        return {
          record_id: record.id,
          sort: recordArr.length - index,
        }
      })
      const sort = { sort: recordSort }
      return TopService.sortRecords(this.sectionId, sort).then((response) => {
        this.drag = false
      }).catch(error => { console.log(error); this.drag = false })
    },
    activateAllRecords() {
      this.publishLoading = true
      return TopService.activeAllRecords(this.sectionId).then((response) => {
        this.publishLoading = false
        this.records = response.data.data.records
        this.$notification.success({
          message: 'All records published',
          description: '',
        })
      }).catch(error => {
        this.$notification.error({
          message: 'Error while publishing records',
          description: error.message,
        })
        this.publishLoading = false
      })
    },
    activeRecordsCount() {
      return this.records ? this.records.filter(obj => obj.is_active === true).length : 0
    },
  },
}
</script>
<style lang="scss" src="./style.module.scss" />
